import React from 'react';

import Article from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import img1 from 'img/help/export-data/1.png';
import img2 from 'img/help/export-data/2.png';
import { Information } from '../../components/information';

const Content = () => {
  return (
    <div>
      <Information color="warning">
        This feature is available only for admins and owners.
      </Information>

      <p>
        If you&apos;d like to export data from your LiveSession account, you can do it from the
        Session&apos;s list. Log in to your account, click on &quot;Sessions&quot; in the left-hand
        menu and click on the Export button in the upper right corner of the list. The export will
        include data of users or events (coming soon) that match the criteria you set using the
        Filters.
      </p>

      <Image src={img1} lazy alt="How to export data from dashboard" title="Export button" />

      <p>
        After clicking the &quot;Export&quot; button, you will see a pop-up with export options. You
        can choose the <b>Export name</b>, <b>Data type</b> (currently only <i>Users</i> are
        supported) and <b>File format</b> (CSV or JSON):
      </p>

      <Image
        src={img2}
        lazy
        alt="Choose export options like name, data type and file format"
        title="Export modal dialog"
      />

      <p>
        Click on the &quot;Export&quot; button and check your inbox for an email. Depending on the
        export size, you may have to wait a couple of minutes to receive it. Your export will be
        also available under Settings {'>'} Exports.
      </p>
    </div>
  );
};

export const frontmatter = {
  metaTitle: 'How to export data from LiveSession?',
  metaDescription: 'Check how you can export data from LiveSession.',
  canonical: '/help/how-to-export-data-from-livesession/',
};

const Wrapped = Article(Content);

export default () => <Wrapped section="Product" title={frontmatter.metaTitle} {...frontmatter} />;
